import React from 'react';
import Select, { SingleValue } from 'react-select';
import { useField } from 'formik';
import { twMerge } from 'tailwind-merge';

type Option = { label: string; value: string | number };

interface SelectInputProps {
  label: string;
  name: string;
  options?: Array<Option>;
  className?: string;
  outerDivClassName?: string;
}

const SelectInput = (props: SelectInputProps) => {
  const { outerDivClassName, className, label, name, options } = props;

  const [field, meta, { setTouched, setValue }] = useField(name);

  const onChange = (option: SingleValue<Option>) => {
    setValue(option?.value);
  };

  return (
    <div className={twMerge('mb-4 ', outerDivClassName)}>
      {label ? (
        <label
          className="block text-gray-700 text-xs font-bold mb-2"
          htmlFor={props.name}
        >
          {label}
        </label>
      ) : null}
      <Select
        {...field}
        onBlur={(e) => {
          setTouched(true);
          field.onBlur(e);
        }}
        styles={{
          menuList: (base, props) => ({
            ...base,
            fontSize: '14px',
          }),
          control: (base, props) => ({
            ...base,
            fontSize: '14px',
            outline: props.isFocused ? '5px solid #ff660020' : '',
            borderColor: props.isFocused
              ? '#ff6600'
              : meta.touched && meta.error
                ? '#eb3c3c'
                : '',
            backgroundColor: props.isFocused ? 'white' : '#eff0f7',
            boxShadow: props.isFocused ? 'none' : '',
          }),
        }}
        components={{
          IndicatorSeparator: () => null,
        }}
        value={options?.find((option) => option.value === field.value)}
        options={options}
        classNamePrefix="input-field-select"
        className={className}
        onChange={onChange}
      />
      {meta.touched && meta.error ? (
        <p className="text-invalid text-xs">{meta.error}</p>
      ) : null}
    </div>
  );
};

export default SelectInput;
