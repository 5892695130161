import React, {
  useRef,
  useEffect,
  useState,
  useMemo,
  useCallback,
} from 'react';
import { Bubble } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { startLoading, stopLoading } from 'Store/loadingSlice';
import { getGraphData } from 'Services/api';
import { registerables, Chart as ChartJS, Tooltip, Legend } from 'chart.js';
import { RootState } from 'Store/store';
import 'chart.js/auto';

// Register required components for Chart.js
ChartJS.register(Tooltip, Legend);
ChartJS.register(...registerables);

const options: any = {
  responsive: true, // Make the chart responsive
  maintainAspectRatio: false,
  plugins: {
    tooltip: {
      enabled: false, // Disable the default tooltip
      external: function (context: any) {
        // Tooltip Element
        let tooltipEl = document.getElementById('custom-tooltip');

        // Create the tooltip element if it doesn't exist
        if (!tooltipEl) {
          tooltipEl = document.createElement('div');
          tooltipEl.id = 'custom-tooltip';
          tooltipEl.innerHTML = '<table border="1"></table>';
          document.body.appendChild(tooltipEl);
        }

        // Hide tooltip if no active element
        const tooltipModel = context.tooltip;
        if (tooltipModel.opacity === 0) {
          tooltipEl.style.opacity = '0';
          return;
        }

        // Set tooltip position
        const position = context.chart.canvas.getBoundingClientRect();
        tooltipEl.style.opacity = '1';
        tooltipEl.style.position = 'absolute';
        tooltipEl.style.left =
          position.left + window.pageXOffset + tooltipModel.caretX + 'px';
        tooltipEl.style.top =
          position.top + window.pageYOffset + tooltipModel.caretY + 'px';
        tooltipEl.style.fontFamily = tooltipModel.options.bodyFont.family;
        tooltipEl.style.fontSize = tooltipModel.options.bodyFont.size + 'px';
        tooltipEl.style.padding =
          tooltipModel.padding + 'px ' + tooltipModel.padding + 'px';
        tooltipEl.style.pointerEvents = 'none';
        tooltipEl.style.backgroundColor = 'white';
        // tooltipEl.style.color = 'white';
        tooltipEl.style.borderRadius = '4px';

        // Set tooltip content
        if (tooltipModel.body) {
          let innerHtml = '<thead><tr>';

          ['Project Name', 'ROI', 'Cost'].forEach((title: any) => {
            innerHtml += '<th>' + title + '</th>';
          });
          innerHtml += '</tr></thead><tbody>';

          tooltipModel.dataPoints.forEach((body: any, i: any) => {
            const { project_name, roi, cost } = body.raw;
            const formattedCost = new Intl.NumberFormat('en-GB', {
              style: 'decimal',
            }).format(cost);
            innerHtml += `<tr><td>${project_name}</td><td>${roi} %</td><td>${formattedCost}</td></tr>`;
          });
          innerHtml += '</tbody>';

          const tableRoot = tooltipEl.querySelector('table')!;
          tableRoot.innerHTML = innerHtml;
        }
      },
    },
    legend: {
      display: false,
    },
    customCanvasBackgroundImage: {
      image: new Image(),
    },
    datalabels: {
      color: 'black',
      font: {
        weight: 'bold',
      },
      formatter: (value: any, context: any) =>
        context.chart.data.datasets[0]?.data[context.dataIndex]?.label || '',
    },
  },
  scales: {
    x: {
      beginAtZero: true,
      grid: {
        display: false, // Disable grid lines on the Y-axis
      },
    },
    y: {
      beginAtZero: true,
    },
  },
};

options.plugins.customCanvasBackgroundImage.image.src =
  '/chart_background.jpeg'; // Set the path to your background image
const customCanvasBackgroundImage = {
  id: 'customCanvasBackgroundImage',
  beforeDraw: (chart: any) => {
    const ctx = chart.canvas.getContext('2d');
    const { top, left, width, height } = chart.chartArea;
    const image = chart.options.plugins.customCanvasBackgroundImage.image;

    if (image.complete && image.naturalHeight !== 0) {
      ctx.save();
      ctx.drawImage(image, left, top, width, height);
      ctx.restore();
    }
  },
};

const GraphView = () => {
  const chartRef = useRef(null);
  const [data, setData] = useState<any>(null);
  const [yAxisLabel, setYAxisLabel] = useState<string>('');
  const [xAxisLabel, setXAxisLabel] = useState<string>('');
  const dispatch = useDispatch();
  const isCollapsed = useSelector(
    (state: RootState) => state.auth.isSideBarCollapsed,
  );

  const customYAxisLabel = useMemo(
    () => ({
      id: 'customYAxisLabel',
      afterDraw: (chart: any) => {
        const {
          ctx,
          scales: { y },
        } = chart;
        ctx.save();
        const middleY = (y.bottom + y.top) / 2;
        ctx.font = 'bold 18px Arial';
        ctx.translate(y.left + 22, middleY + 50);
        ctx.rotate(-Math.PI / 2);
        ctx.textAlign = 'center';
        ctx.fillText(yAxisLabel, 50, 0);
        ctx.restore();
      },
    }),
    [yAxisLabel],
  );

  const customXAxisLabel = useMemo(
    () => ({
      id: 'customXAxisLabel',
      afterDraw: (chart: any) => {
        const {
          ctx,
          scales: { x },
        } = chart;
        ctx.save();
        const middleX = (x.left + x.right) / 2;
        ctx.font = 'bold 18px Arial';
        ctx.textAlign = 'center';
        ctx.fillText(xAxisLabel, middleX, x.bottom - 5);
        ctx.restore();
      },
    }),
    [xAxisLabel],
  );

  const getChartData = useCallback(async () => {
    dispatch(startLoading());
    try {
      const result = await getGraphData();
      setXAxisLabel(result.x_axis_label);
      setYAxisLabel(result.y_axis_label);
      options.scales.x.max = result.x_range[1];
      options.scales.x.ticks = {
        callback: function (value: number) {
          const labels: any = {
            0: 'Low',
            [result.x_range[1] / 2]: '',
            [result.x_range[1]]: 'High',
          };
          return labels[value] || '';
        },
      };
      options.scales.y.max = result.y_range[1];
      options.scales.y.ticks = {
        callback: function (value: number) {
          const labels: any = {
            0: 'Hard',
            [result.y_range[1] / 2]: '',
            [result.y_range[1]]: 'Easy',
          };
          return labels[value] || '';
        },
      };
      setData({
        datasets: result.data,
      });
    } catch (error) {
      console.error(error);
      toast.error('Something went wrong!');
    }
    dispatch(stopLoading());
  }, [dispatch]);

  useEffect(() => {
    getChartData();
    const chart = chartRef.current as any;
    const image = options.plugins.customCanvasBackgroundImage.image;

    const onImageLoad = () => {
      if (chart) {
        chart.update();
      }
    };

    image.addEventListener('load', onImageLoad);

    return () => {
      image.removeEventListener('load', onImageLoad);
      // Cleanup on unmount
      if (chart) {
        chart.destroy();
      }
    };
  }, [getChartData]);

  useEffect(() => {
    const chart = chartRef.current as any;
    if (data && chart) {
      chart.update();
    }
  }, [data, isCollapsed]);

  if (!data) {
    return null;
  }

  return (
    <div
      key={Math.random()}
      className="bg-white rounded-lg mt-4"
      style={{
        position: 'relative',
        width: '100%',
        maxWidth: '100%',
        height: '80vh',
        maxHeight: '80vh',
      }}
    >
      <Bubble
        ref={chartRef}
        data={data}
        options={options}
        plugins={[
          ChartDataLabels,
          customCanvasBackgroundImage,
          customYAxisLabel,
          customXAxisLabel,
        ]}
      />
    </div>
  );
};

export default GraphView;
