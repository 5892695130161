import React, { useState } from 'react';
import { ChromePicker, ColorResult } from 'react-color';
import { useField, useFormikContext } from 'formik';

interface ColorPickerProps {
  label: string;
  name: string;
  colorLabel?: string;
  className?: string;
}

const ColorPicker = ({
  className,
  label,
  colorLabel,
  ...props
}: ColorPickerProps) => {
  const [field, meta] = useField(props);
  const { setFieldValue } = useFormikContext();
  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  const handleChange = (color: ColorResult) => {
    setFieldValue(field.name, color.hex);
  };

  return (
    <div
      className={className}
      style={{ position: 'relative', display: 'inline-block' }}
    >
      <label
        htmlFor={props.name}
        className="block text-gray-700 text-xs font-bold mb-2"
      >
        {label}
      </label>
      <div
        onClick={handleClick}
        className="flex items-center border rounded p-1"
      >
        <div
          className="w-3 h-3 rounded-full mr-2"
          style={{
            backgroundColor: field.value,
          }}
        />
        <label htmlFor={colorLabel}>{colorLabel}</label>
      </div>
      {displayColorPicker ? (
        <div style={{ position: 'absolute', zIndex: '2', top: '50px' }}>
          <div
            style={{
              position: 'fixed',
              top: '0px',
              right: '0px',
              bottom: '0px',
              left: '0px',
            }}
            onClick={handleClose}
          />
          <ChromePicker color={field.value} onChange={handleChange} />
        </div>
      ) : null}
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </div>
  );
};

export default ColorPicker;
