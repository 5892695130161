import Input from 'Components/Input';
import { ProjectSetupFormType } from './index';
import { twMerge } from 'tailwind-merge';

type ProjectBubbleSetupProps = {
  values: ProjectSetupFormType;
  className?: string;
  settingsTab?: boolean;
};

const ProjectBubbleSetup = (props: ProjectBubbleSetupProps) => {
  const { className, settingsTab } = props;
  return (
    <div className={twMerge('flex-col w-full', className)}>
      <div
        className={`flex-col w-full ${settingsTab ? 'border-r mr-2 pr-2' : ''}`}
      >
        <div className="flex w-full justify-center">
          <Input
            label="Project Cost"
            name="project_cost"
            type="text"
            placeholder="000"
            // preText="£"
          />
        </div>

        <div className="flex w-full justify-center">
          <Input
            label="Project % ROI"
            name="project_roi"
            type="text"
            placeholder="00"
            preText="%"
          />
        </div>
      </div>
    </div>
  );
};

export default ProjectBubbleSetup;
