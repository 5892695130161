import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { FaRegUserCircle } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { addUser, updateUser } from 'Services/api';
import { startLoading, stopLoading } from 'Store/loadingSlice';
import CommonModal from 'Components/CommonModal';
import Input from 'Components/Input';
import Button from 'Components/Button';

type FormType = {
  name: string;
  email: string;
};

type AddEditUserModalProps = {
  userData: {
    user_id: string;
    name: string;
    email: string;
  };
  isOpen: boolean;
  onModalClose: () => void;
  doRefresh: () => void;
};

const AddEditUserModal = (props: AddEditUserModalProps) => {
  const { isOpen, onModalClose, doRefresh, userData } = props;
  const dispatch = useDispatch();

  const initialValues = {
    name: userData?.name || '',
    email: userData?.email || '',
  };

  const validationSchema = Yup.object({
    name: Yup.string().required('Required'),
    email: Yup.string().email('Invalid email address').required('Required'),
  });

  const onSubmit = async (values: FormType) => {
    dispatch(startLoading());
    try {
      const { name, email } = values;
      let result;
      if (userData?.user_id) {
        result = await updateUser(
          {
            name: name,
            email: email,
          },
          userData?.user_id,
        );
      } else {
        result = await addUser({
          name: name,
          email: email,
        });
      }
      if (result.success) {
        doRefresh();
        toast.success(userData ? 'User updated!' : 'User added successfully!');
      } else {
        toast.error('Something went wrong!');
      }
    } catch (error) {
      console.error(error);
      toast.warning('User exists!');
    }
    dispatch(stopLoading());
    onModalClose();
  };

  return (
    <CommonModal
      modalTitle={`${userData?.user_id ? 'Edit' : 'Add'} User`}
      isOpen={isOpen}
      onModalClose={onModalClose}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        <Form className="w-full mt-4">
          <div className="flex flex-col w-full">
            <label
              className="block text-gray-700 text-xs font-bold mb-2"
              htmlFor="name"
            >
              User Details
            </label>
            <div className="flex w-full justify-center">
              <span className="flex justify-center items-center bg-[#eff0f7] rounded-lg h-[40px] w-[40px] mr-2">
                <FaRegUserCircle size={20} />
              </span>
              <Input
                label=""
                name="name"
                type="text"
                placeholder="Enter name, ex. Joe Smith"
              />
            </div>
            <div className="flex w-full justify-center">
              <span className="h-[40px] w-[40px] mr-2 mt-2"></span>
              <Input
                label=""
                name="email"
                type="text"
                placeholder="Enter email, ex. joesmith@nakedleader.co.uk"
              />
            </div>
          </div>

          <div className="flex items-center justify-between md:mt-8">
            <Button text="Done" />
          </div>
        </Form>
      </Formik>
    </CommonModal>
  );
};

export default AddEditUserModal;
