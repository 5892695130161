type ActionButtonsProps = {
  onEditClick: () => void;
  onDeleteClick: () => void;
  hideAction?: boolean;
};
const ActionButtons = (props: ActionButtonsProps) => {
  const { onEditClick, onDeleteClick, hideAction } = props;
  return (
    <div className="flex justify-center">
      <button
        onClick={hideAction ? undefined : onEditClick}
        className={`text-xs cursor-pointer ${hideAction ? 'text-gray-400' : 'text-primary hover:text-primary-500'}`}
        disabled={hideAction}
      >
        Edit
      </button>
      <button
        onClick={hideAction ? undefined : onDeleteClick}
        className={`text-xs cursor-pointer ml-1 ${hideAction ? 'text-gray-400' : 'text-invalid hover:text-invalid-500'}`}
        disabled={hideAction}
      >
        Delete
      </button>
    </div>
  );
};

export default ActionButtons;
