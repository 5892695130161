import React from 'react';

type NoDataFoundProps = { customMessage?: string };

const NoDataFound = ({ customMessage }: NoDataFoundProps) => {
  return (
    <div className="flex items-center justify-center min-h-[70vh] bg-gray-100">
      <div className="bg-white rounded-lg shadow-lg p-6 text-center">
        <p className="text-gray-500 text-xl font-semibold">
          {customMessage || 'No Data Found'}
        </p>
      </div>
    </div>
  );
};

export default NoDataFound;
