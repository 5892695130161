import React, { useState, useRef, useEffect } from 'react';
import { FaUser } from 'react-icons/fa';
import { IoMenu, IoMenuOutline } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { logout, setIsSideBarCollapsed } from 'Store/authSlice';
import Sidebar from 'Components/Sidebar';
import { sideBarRoutes } from 'Constants';
import { RootState } from 'Store/store';

type LayoutProps = {
  component: any;
};

const Layout: React.FC<LayoutProps> = (props: LayoutProps) => {
  const { component: Component } = props;

  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const isCollapsed = useSelector(
    (state: RootState) => state.auth.isSideBarCollapsed,
  );
  const [title, setTitle] = useState<string>('');
  const dropdownRef = useRef<any>(null);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleClickOutside = (event: any) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    setTitle(
      sideBarRoutes.find((route) => route.route === location.pathname)?.label ||
        '',
    );
  }, [location]);

  useEffect(() => {
    if (dropdownOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownOpen]);

  return (
    <div className="flex relative">
      <Sidebar
        isCollapsed={isCollapsed}
        setIsCollapsed={(value) => dispatch(setIsSideBarCollapsed(value))}
      />
      <div
        className={`min-h-screen flex flex-col bg-gray-100 ml-[${isCollapsed ? '250px' : '60px'}] w-full`}
      >
        <div className="bg-white p-4 shadow">
          <div className="container w-full flex justify-between items-center">
            <button
              className="rounded toggle-button"
              onClick={() => dispatch(setIsSideBarCollapsed(!isCollapsed))}
            >
              {isCollapsed ? <IoMenu /> : <IoMenuOutline />}
            </button>
            {/* Title */}
            <div className="text-xl md:text-2xl font-medium">{title}</div>

            {/* User Dropdown */}
            <div className="relative" ref={dropdownRef}>
              <button
                onClick={toggleDropdown}
                type="button"
                className="flex items-center text-white focus:outline-none"
              >
                <div className="rounded bg-gray-200 pt-1 pl-1 pr-1">
                  <FaUser color="gray" size={22} />
                </div>
                <svg
                  className="w-4 h-4 ml-2"
                  fill="none"
                  stroke="black"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </button>

              {/* Dropdown Menu */}
              {dropdownOpen && (
                <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-20">
                  <button
                    type="button"
                    onClick={() => {
                      navigate('/reset-password');
                      setDropdownOpen(false);
                    }}
                    className="w-full block px-4 py-2 text-gray-800 hover:bg-gray-200"
                  >
                    Reset Password
                  </button>
                  <hr />
                  <button
                    type="button"
                    onClick={() => logout(dispatch)}
                    className="w-full block px-4 py-2 text-gray-800 hover:bg-gray-200"
                  >
                    Logout
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>

        <Component />
      </div>
    </div>
  );
};

export default Layout;
