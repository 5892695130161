import { twMerge } from 'tailwind-merge';

type TabsProps = {
  tabs: Array<{
    label: string;
    value: string;
    hidden: boolean;
  }>;
  onClick: (tab: string) => void;
  selectedTab: string;
};

const Tabs = (props: TabsProps) => {
  const { tabs, onClick, selectedTab } = props;

  const handleOnClick = (id: string) => {
    onClick(id);
  };

  return (
    <div className="w-full flex">
      {tabs
        ?.filter((tab) => !tab.hidden)
        ?.map((tab) => (
          <div
            key={tab.value}
            className={twMerge(
              `flex justify-center items-center w-full bg-white p-3`,
            )}
            onClick={() => handleOnClick(tab.value)}
          >
            <div
              className={`tab flex flex-col w-full p-2 text-md font-bold cursor-pointer hover:bg-gray-100 rounded-lg justify-center items-center ${selectedTab === tab.value ? 'bg-gray-100' : ''}`}
            >
              {tab.label}
              {selectedTab === tab.value ? (
                <div className="flex justify-center">
                  <div className="block h-1 bg-primary w-7 mt-1" />
                </div>
              ) : null}
            </div>
          </div>
        ))}
    </div>
  );
};

export default Tabs;
