import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Input from 'Components/Input';
import { PiHeadsetDuotone } from 'react-icons/pi';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import Button from 'Components/Button';
import { logoPNG, watermarkLogoPNG } from 'Assets';
import { login } from 'Services/api';
import { startLoading, stopLoading } from 'Store/loadingSlice';
import { loginAction } from 'Store/authSlice';
import ForgotPasswordModal from './ForgotPasswordModal';

const Login: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [showForgotPassword, setForgotPassword] = useState(false);

  const initialValues = {
    email: '',
    password: '',
  };

  const validationSchema = Yup.object({
    email: Yup.string().email('Invalid email address').required('Required'),
    password: Yup.string().required('Required'),
  });

  const onSubmit = async (values: { email: string; password: string }) => {
    dispatch(startLoading());
    try {
      const { email, password } = values;
      const result = await login(email, password);
      if (result.success) {
        // Store the token in localStorage or sessionStorage
        localStorage.setItem('token', result.access_token);
        localStorage.setItem('role', result.role);
        dispatch(loginAction(result));
        navigate('/dashboard');
      } else {
        alert('Invalid username or password');
      }
    } catch (error) {
      alert('Invalid username or password');
    }
    dispatch(stopLoading());
  };

  return (
    <div className="min-h-full md:min-h-screen flex flex-col-reverse md:flex-row">
      {/* Left side with white background */}
      <div className="w-full md:w-1/2 bg-white flex flex-col items-center justify-center">
        <div className="w-full flex-grow p-5 md:p-[100px]">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            <Form className="w-full md:p-8">
              <h1 className="text-32px font-bold mb-8 text-center md:text-left">
                Login to Watermark
              </h1>
              <Input
                label="Email"
                name="email"
                type="email"
                placeholder="Email"
              />
              <div className="relative">
                <Input
                  label="Password"
                  name="password"
                  type={showPassword ? 'text' : 'password'}
                  placeholder="******************"
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)} // Toggle the state
                  className="absolute right-3 top-8 text-gray-500"
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </button>
              </div>
              <div className="flex items-center justify-between md:mt-8">
                <Button type="submit" text="Login" />
              </div>
              <p className="text-xs font-medium mt-3">
                By logging in, you agree to Naked Leader{' '}
                <a href="https://nakedleader.com" className="text-primary">
                  Terms of Service
                </a>{' '}
                and{' '}
                <a href="https://nakedleader.com" className="text-primary">
                  Privacy policy.{' '}
                </a>
              </p>
              <p className="text-xs font-medium mt-3">
                <p
                  className="text-primary cursor-pointer"
                  onClick={() => setForgotPassword(true)}
                >
                  Forgot Password?
                </p>
              </p>
            </Form>
          </Formik>
        </div>
        <footer className="bg-footer text-white py-4 w-full">
          <div className="container mx-auto px-4 flex justify-start">
            <div className="p-2 mr-2 text-white rounded-sm backdrop-brightness-90">
              <PiHeadsetDuotone size={20} />
            </div>
            <div className="text-left flex flex-col">
              <p className="font-bold text-sm">Contact Us</p>
              <p className="text-xs">
                To get login credentials please contact us on{' '}
                <a
                  href="mailto:info@nakedleader.com"
                  className="font-bold underline"
                >
                  info@nakedleader.com
                </a>{' '}
                or{' '}
                <a href="tel:+441483722205" className="font-bold underline">
                  +44 1483 722205
                </a>
              </p>
            </div>
          </div>
        </footer>
      </div>

      {/* Right side with black background and logo */}
      <div className="w-full md:w-1/2 bg-black flex items-center justify-center p-4 md:p-8">
        <div className="text-white text-2xl font-bold">
          <img
            src={watermarkLogoPNG}
            className="w-[150px] md:w-[365px]"
            alt="Watermark Logo"
          />
          <img
            src={logoPNG}
            className="mt-20 w-[150px] h-[60px] md:w-[365px] md:h-[163px]"
            alt="Naked Leader Logo"
          />
        </div>
      </div>
      <ForgotPasswordModal
        isOpen={showForgotPassword}
        onModalClose={() => setForgotPassword(false)}
      />
    </div>
  );
};

export default Login;
