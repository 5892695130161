import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { FaRegUserCircle } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { addCompany, updateCompany } from 'Services/api';
import { startLoading, stopLoading } from 'Store/loadingSlice';
import CommonModal from 'Components/CommonModal';
import Input from 'Components/Input';
import Button from 'Components/Button';

type AddEditBusinessPartnerModalProps = {
  businessPartnerData: {
    company_id: string;
    company_name: string;
    email: string;
    name: string;
  };
  isOpen: boolean;
  onModalClose: () => void;
  doRefresh: () => void;
};

const AddEditBusinessPartnerModal = (
  props: AddEditBusinessPartnerModalProps,
) => {
  const { businessPartnerData, isOpen, onModalClose, doRefresh } = props;
  const dispatch = useDispatch();

  const initialValues = {
    business_partner: businessPartnerData?.company_name || '',
    business_partner_champion_name: businessPartnerData?.name || '',
    business_partner_champion_email: businessPartnerData?.email || '',
  };

  type FormType = typeof initialValues;

  const validationSchema = Yup.object({
    business_partner: Yup.string().required('Required'),
    business_partner_champion_name: Yup.string().required('Required'),
    business_partner_champion_email: Yup.string()
      .email('Invalid email address')
      .required('Required'),
  });

  const onSubmit = async (values: FormType) => {
    dispatch(startLoading());
    try {
      const {
        business_partner,
        business_partner_champion_name,
        business_partner_champion_email,
      } = values;

      let result;
      if (businessPartnerData?.company_id) {
        result = await updateCompany(
          {
            name: business_partner_champion_name,
            email: business_partner_champion_email,
            company_name: business_partner,
          },
          businessPartnerData?.company_id,
        );
      } else {
        result = await addCompany({
          name: business_partner_champion_name,
          email: business_partner_champion_email,
          company_name: business_partner,
        });
      }
      if (result.success) {
        doRefresh();
        toast.success(
          businessPartnerData?.company_id
            ? 'Business partner details updated!'
            : 'Business partner added successfully!',
        );
      } else {
        toast.error('Something went wrong!');
      }
    } catch (error: any) {
      console.error(error);
      if (error?.response?.status === 400) {
        toast.warning(error.response.data.detail);
      } else {
        toast.error('Something went wrong!');
      }
    }
    dispatch(stopLoading());
    onModalClose();
  };

  return (
    <CommonModal
      modalTitle={
        businessPartnerData?.company_id
          ? 'Edit Business partner'
          : 'Add Business Partner'
      }
      isOpen={isOpen}
      onModalClose={onModalClose}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        <Form className="w-full mt-4">
          <Input
            label="Business Partner"
            name="business_partner"
            type="text"
            placeholder="Business Partner Example"
          />
          <div className="flex flex-col w-full">
            <label
              className="block text-gray-700 text-xs font-bold mb-2"
              htmlFor="business_partner_champion_name"
            >
              Business Partner Champion
            </label>
            <div className="flex w-full justify-center">
              <span className="flex justify-center items-center bg-[#eff0f7] rounded-lg h-[40px] w-[40px] mr-2">
                <FaRegUserCircle size={20} />
              </span>
              <Input
                label=""
                name="business_partner_champion_name"
                type="text"
                placeholder="ex. Joe Smith"
              />
            </div>
            <div className="flex w-full justify-center">
              <span className="h-[40px] w-[40px] mr-2 mt-2"></span>
              <Input
                label=""
                name="business_partner_champion_email"
                type="text"
                placeholder="ex. joesmith@nakedleader.co.uk"
              />
            </div>
          </div>

          <div className="flex items-center justify-between md:mt-8">
            <Button text="Done" />
          </div>
        </Form>
      </Formik>
    </CommonModal>
  );
};

export default AddEditBusinessPartnerModal;
