import { permissions, Roles } from 'Constants';
import Layout from 'Pages/Layout';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { loginAction } from 'Store/authSlice';
import { RootState } from 'Store/store';

interface ProtectedRouteProps {
  path: string;
  component: React.ComponentType;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = (
  props: ProtectedRouteProps,
) => {
  const { path, component: Component } = props;
  const dispatch = useDispatch();
  const { isAuthenticated, role } = useSelector(
    (state: RootState) => state.auth,
  );

  const token = localStorage.getItem('token');
  const roleLocalStorage = localStorage.getItem('role');

  if (!isAuthenticated && token) {
    dispatch(loginAction({ token, role: roleLocalStorage }));
  }

  if (!isAuthenticated && !token) {
    return <Navigate to="/login" replace />;
  }

  if (!permissions[role as Roles]?.includes(path)) {
    return <Navigate to="/" replace />; // Redirect to dashboard if the role is not allowed
  }

  return <Layout component={Component} />;
};

export default ProtectedRoute;
