import Button from 'Components/Button';
import { Formik, Form, FormikHelpers } from 'formik';
import { useState } from 'react';
import * as Yup from 'yup';
import XAxisSetup from './XAxisSetup';
import YAxisSetup from './YAxisSetup';
import BubbleSetup from './BubbleSetup';

export type BusinessPartnerSetupFormType = {
  low_color: string;
  medium_color: string;
  project_cost_amount_from: number;
  project_cost_amount_to: number;
  high_color: string;
  size_small: string;
  size_medium: string;
  size_large: string;
  project_roi_return_from: number;
  project_roi_return_to: number;
  xaxis_name: string;
  yaxis_name: string;
  xaxis_questions: Array<{ id: number; question: string; weight: number }>;
  yaxis_questions: Array<{ id: number; question: string; weight: number }>;
};

const BusinessPartnerSetup = () => {
  const [step, setStep] = useState<number>(1);

  const initialValues: BusinessPartnerSetupFormType = {
    low_color: '#FF0000',
    medium_color: '#00FF00',
    high_color: '#0000FF',
    project_cost_amount_from: 0,
    project_cost_amount_to: 0,

    size_small: 'Small',
    size_medium: 'Medium',
    size_large: 'Large',
    project_roi_return_from: 0,
    project_roi_return_to: 0,

    xaxis_name: 'test',
    yaxis_name: 'test',
    xaxis_questions: [
      { id: 1, question: 't', weight: 0 },
      { id: 1, question: 't', weight: 0 },
      { id: 1, question: 't', weight: 0 },
      { id: 1, question: 't', weight: 0 },
      { id: 1, question: 't', weight: 0 },
    ],
    yaxis_questions: [
      { id: 1, question: '1', weight: 0 },
      { id: 1, question: '1', weight: 0 },
      { id: 1, question: '1', weight: 0 },
      { id: 1, question: '1', weight: 0 },
      { id: 1, question: '1', weight: 0 },
    ],
  };

  const stepOneValidationSchema = Yup.object({
    xaxis_name: Yup.string().required('Required'),
    xaxis_questions: Yup.array().of(
      Yup.object({
        question: Yup.string().required('Question is required'),
        weight: Yup.string().required('Weight is required'),
      }),
    ),
  });

  const stepTwoValidationSchema = Yup.object({
    yaxis_name: Yup.string().required('Required'),
    yaxis_questions: Yup.array().of(
      Yup.object({
        question: Yup.string().required('Question is required'),
        weight: Yup.string().required('Weight is required'),
      }),
    ),
  });

  const stepThreeValidationSchema = Yup.object({
    low_color: Yup.string().required('Required'),
    medium_color: Yup.string().required('Required'),
    high_color: Yup.string().required('Required'),
    project_cost_amount_from: Yup.string().required('Required'),
    project_cost_amount_to: Yup.string().required('Required'),

    size_small: Yup.string().required('Required'),
    size_medium: Yup.string().required('Required'),
    size_large: Yup.string().required('Required'),
    project_roi_return_from: Yup.string().required('Required'),
    project_roi_return_to: Yup.string().required('Required'),
  });

  const getValidationSchema = () => {
    switch (step) {
      case 1:
        return stepOneValidationSchema;
      case 2:
        return stepTwoValidationSchema;
      case 3:
        return stepThreeValidationSchema;
      default:
        return stepOneValidationSchema;
    }
  };

  //   const onSubmit = (values: FormType) => {
  // alert(JSON.stringify(values, null, 2));
  // navigate('/dashboard');
  // onModalClose();
  //   };

  const handleSubmit = (
    values: BusinessPartnerSetupFormType,
    actions: FormikHelpers<BusinessPartnerSetupFormType>,
  ) => {
    if (step === 3) {
      // Final step: submit form
      console.log(values);
      actions.setSubmitting(false);
    } else {
      // Go to next step
      setStep(step + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  const getTitleText = () => {
    switch (step) {
      case 1:
        return 'X-axis setup';
      case 2:
        return 'Y-axis setup';
      case 3:
        return 'Bubble';
      default:
        return 'X-axis setup';
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white p-10 rounded-lg shadow-lg">
        <div className="flex justify-between items-center">
          <div className="text-2xl font-bold">{getTitleText()}</div>
          <div className="text-base text-primary">Step {step}/3</div>
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={getValidationSchema()}
          onSubmit={handleSubmit}
        >
          {({ values }) => (
            <Form className="w-full mt-4">
              {step === 1 ? <XAxisSetup values={values} /> : null}
              {step === 2 ? <YAxisSetup values={values} /> : null}
              {step === 3 ? <BubbleSetup values={values} /> : null}
              <div className="flex items-center justify-between md:mt-8">
                <Button
                  text={step > 1 ? 'Back' : 'Cancel'}
                  type="button"
                  onClick={() => {
                    if (step > 1) {
                      setStep((step) => step - 1);
                    } else {
                      // @TODO History push to back
                    }
                  }}
                  className="bg-secondary hover:bg-secondary-500 text-black"
                />
                <Button
                  text={step === 3 ? 'Finish' : 'Continue'}
                  type="submit"
                  className="ml-2 min-w-[250px]"
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};
export default BusinessPartnerSetup;
