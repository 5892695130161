import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { Roles } from 'Constants';

export interface AuthState {
  token: string;
  isAuthenticated: boolean;
  role: Roles | '';
  isSideBarCollapsed: boolean;
}

const initialState: AuthState = {
  token: '',
  isAuthenticated: false,
  role: '',
  isSideBarCollapsed: false,
};

export const loadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    setIsSideBarCollapsed: (state, action) => {
      state.isSideBarCollapsed = action.payload;
    },
    loginAction: (state, action) => {
      state.isAuthenticated = true;
      state.role = action.payload.role;
      state.token = action.payload.access_token;
    },
    logoutAction: (state) => {
      state.isAuthenticated = false;
      state.role = '';
      state.token = '';
    },
  },
});

export const logout = (dispatch: Dispatch) => {
  localStorage.removeItem('token');
  localStorage.removeItem('role');
  dispatch(logoutAction());
};

export const { loginAction, logoutAction, setIsSideBarCollapsed } =
  loadingSlice.actions;

export default loadingSlice.reducer;
