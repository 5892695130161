import { configureStore } from '@reduxjs/toolkit';
import loadingReducer, { LoadingState } from './loadingSlice';
import authSlice, { AuthState } from './authSlice';

export interface RootState {
  loading: LoadingState;
  auth: AuthState;
}

export const store = configureStore({
  reducer: {
    loading: loadingReducer,
    auth: authSlice,
  },
});

export default store;
