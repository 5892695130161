import React, { useEffect, useMemo, useState } from 'react';
import { logoPNG, watermarkLogoPNG } from 'Assets';
import { IoMenu } from 'react-icons/io5';
import { IoIosArrowBack } from 'react-icons/io';
import { useLocation, useNavigate } from 'react-router-dom';
import { getPermittedRoutes, Roles } from 'Constants';
import { useSelector } from 'react-redux';
import { RootState } from 'Store/store';

type SidebarProps = {
  isCollapsed: boolean;
  setIsCollapsed: (value: boolean) => void;
};

const Sidebar = (props: SidebarProps) => {
  const { isCollapsed, setIsCollapsed } = props;
  const [currentRoute, setCurrentRoute] = useState<string>('');
  const location = useLocation();
  const navigate = useNavigate();
  const currentUserRole = useSelector(
    (state: RootState) => state.auth.role,
  ) as Roles;

  useEffect(() => {
    setCurrentRoute(location.pathname);
  }, [location]);

  const menu = useMemo(
    () =>
      getPermittedRoutes(currentUserRole).filter((item) => item.isPermitted), // Only show permitted menu items
    [currentUserRole],
  );

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div
      className={`sidebar flex flex-col justify-between ${isCollapsed ? 'collapsed' : ''} ${window.innerWidth < 768 && !isCollapsed ? 'mobile-overlay' : ''}`}
    >
      <div>
        <div className="flex justify-between items-center">
          {!isCollapsed ? (
            <img
              src={logoPNG}
              className="w-[100px] h-[40px]"
              alt="Naked Leader Logo"
            />
          ) : null}
          {window.innerWidth < 768 && !isCollapsed ? (
            <button className="rounded toggle-button" onClick={toggleSidebar}>
              {isCollapsed ? <IoMenu /> : <IoIosArrowBack />}
            </button>
          ) : null}
        </div>
        {!isCollapsed ? (
          <ul>
            {menu.map((item, index) => {
              return (
                <li
                  key={index}
                  className={`rounded p-1 ${item.route === currentRoute ? 'bg-gray-700' : ''}`}
                  onClick={() => navigate(item.route)}
                >
                  {item.label}
                </li>
              );
            })}
          </ul>
        ) : null}
      </div>

      {!isCollapsed ? (
        <div className="flex justify-between items-center pl-8 pb-4">
          <img
            src={watermarkLogoPNG}
            className="h-[150px]"
            alt="Watermark Logo"
          />
        </div>
      ) : null}
    </div>
  );
};

export default Sidebar;
