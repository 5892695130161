import Button from 'Components/Button';
import { Form, Formik } from 'formik';
import { BusinessPartnerSetupFormType } from 'Pages/BusinessPartnerSetup';
import BubbleSetup from 'Pages/BusinessPartnerSetup/BubbleSetup';
import XAxisSetup from 'Pages/BusinessPartnerSetup/XAxisSetup';
import YAxisSetup from 'Pages/BusinessPartnerSetup/YAxisSetup';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getCompanySettings, updateCompanySettings } from 'Services/api';
import { startLoading, stopLoading } from 'Store/loadingSlice';
import { CompanySettingsResponse } from 'Types';
import * as Yup from 'yup';

const Settings = () => {
  const [companySettingsResponse, setCompanySettingsResponse] =
    useState<CompanySettingsResponse | null>(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const initialValues: BusinessPartnerSetupFormType = useMemo(() => {
    return {
      low_color:
        companySettingsResponse?.company_settings?.cost?.colors?.low || '',
      high_color:
        companySettingsResponse?.company_settings?.cost?.colors?.high || '',
      medium_color:
        companySettingsResponse?.company_settings?.cost?.colors?.medium || '',
      project_cost_amount_from:
        companySettingsResponse?.company_settings?.cost?.range?.[0] || 0,
      project_cost_amount_to:
        companySettingsResponse?.company_settings?.cost?.range?.[1] || 0,
      size_small: 'Small',
      size_medium: 'Medium',
      size_large: 'Large',
      project_roi_return_from:
        companySettingsResponse?.company_settings?.roi?.range?.[0] || 0,
      project_roi_return_to:
        companySettingsResponse?.company_settings?.roi?.range?.[1] || 0,
      xaxis_name: companySettingsResponse?.company_settings?.x_axis_label || '',
      yaxis_name: companySettingsResponse?.company_settings?.y_axis_label || '',
      xaxis_questions:
        companySettingsResponse?.company_settings?.x_axis_questions?.map(
          (q) => ({
            id: q.id,
            question: q.question || '',
            weight: q.weight || 0,
          }),
        ) || [],
      yaxis_questions:
        companySettingsResponse?.company_settings?.y_axis_questions?.map(
          (q) => ({
            id: q.id,
            question: q.question || '',
            weight: q.weight || 0,
          }),
        ) || [],
    };
  }, [companySettingsResponse]);

  const validationSchema = Yup.object({
    xaxis_name: Yup.string().required('Required'),
    xaxis_questions: Yup.array().of(
      Yup.object({
        question: Yup.string().required('Question is required'),
        weight: Yup.string().required('Weight is required'),
      }),
    ),
    yaxis_name: Yup.string().required('Required'),
    yaxis_questions: Yup.array().of(
      Yup.object({
        question: Yup.string().required('Question is required'),
        weight: Yup.string().required('Weight is required'),
      }),
    ),
    low_color: Yup.string().required('Required'),
    medium_color: Yup.string().required('Required'),
    high_color: Yup.string().required('Required'),
    project_cost_amount_from: Yup.string().required('Required'),
    project_cost_amount_to: Yup.string().required('Required'),

    size_small: Yup.string().required('Required'),
    size_medium: Yup.string().required('Required'),
    size_large: Yup.string().required('Required'),
    project_roi_return_from: Yup.string().required('Required'),
    project_roi_return_to: Yup.string().required('Required'),
  });

  const handleSubmit = async (values: BusinessPartnerSetupFormType) => {
    dispatch(startLoading());
    try {
      const result = await updateCompanySettings({
        x_axis_label: values?.xaxis_name,
        y_axis_label: values?.yaxis_name,
        x_axis_questions: values.xaxis_questions.map((ans) => ({
          id: ans.id,
          question: ans.question,
          weight: ans.weight,
        })),
        y_axis_questions: values.yaxis_questions.map((ans) => ({
          id: ans.id,
          question: ans.question,
          weight: ans.weight,
        })),
        cost: {
          colors: {
            low: values.low_color,
            medium: values.medium_color,
            high: values.high_color,
          },
          range: [
            parseFloat(values.project_cost_amount_from?.toString()),
            parseFloat(values.project_cost_amount_to?.toString()),
          ],
        },
        roi: {
          type: {
            small: values.size_small,
            medium: values.size_medium,
            large: values.size_large,
          },
          range: [
            parseFloat(values.project_roi_return_from?.toString()),
            parseFloat(values.project_roi_return_to?.toString()),
          ],
        },
      });
      if (result.success) {
        toast.success('Company settings updated!');
        navigate('/dashboard?selectedTab=settings');
      } else {
        toast.error('Something went wrong!');
      }
    } catch (error) {
      console.error(error);
      toast.error('Something went wrong!');
    }
    dispatch(stopLoading());
  };

  const getSettingsData = useCallback(async () => {
    dispatch(startLoading());
    const data = await getCompanySettings();
    setCompanySettingsResponse(data);
    dispatch(stopLoading());
  }, [dispatch]);

  useEffect(() => {
    getSettingsData();
  }, [getSettingsData]);

  return (
    <div className="flex-col w-full mt-3 rounded bg-white p-5">
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, errors }) => (
          <Form className="w-full mt-4">
            <div className="flex">
              <YAxisSetup className="border-r pr-3 mr-3 mb-2" values={values} />
              <XAxisSetup className="mb-2" values={values} />
            </div>
            <hr />
            <div className="flex">
              <BubbleSetup
                settingsTab
                className="flex flex-row mt-3"
                values={values}
              />
            </div>
            <div className="flex justify-end">
              <Button
                text={'Save'}
                type="submit"
                className="ml-2 min-w-[250px] w-auto"
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
export default Settings;
