import Button from './Button';
import CommonModal from './CommonModal';

type ConfirmDeleteModalProps = {
  modalTitle: string;
  modalText: string;
  isOpen: boolean;
  onModalClose: () => void;
  onConfirm: () => void;
};

const ConfirmDeleteModal = (props: ConfirmDeleteModalProps) => {
  const { modalTitle, modalText, isOpen, onModalClose, onConfirm } = props;

  return (
    <CommonModal
      modalTitle={modalTitle}
      isOpen={isOpen}
      onModalClose={onModalClose}
    >
      <>
        <div className="flex flex-col w-full mt-5">{modalText}</div>
        <div className="flex items-center justify-between md:mt-8">
          <Button text="Confirm" onClick={onConfirm} />
          <Button
            text={'Cancel'}
            type="button"
            onClick={onModalClose}
            className="bg-secondary hover:bg-secondary-500 text-black ml-2"
          />
        </div>
      </>
    </CommonModal>
  );
};

export default ConfirmDeleteModal;
