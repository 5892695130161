import Input from 'Components/Input';
import Button from '../Components/Button';
import CommonModal from '../Components/CommonModal';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { startLoading, stopLoading } from 'Store/loadingSlice';
import { forgotPassword } from 'Services/api';
import { toast } from 'react-toastify';

type ForgotPasswordModalProps = {
  isOpen: boolean;
  onModalClose: () => void;
};

const ForgotPasswordModal = (props: ForgotPasswordModalProps) => {
  const { isOpen, onModalClose } = props;
  const dispatch = useDispatch();

  const initialValues = {
    email: '',
  };

  const validationSchema = Yup.object({
    email: Yup.string().email('Invalid email address').required('Required'),
  });

  const onSubmit = async (values: { email: string }) => {
    dispatch(startLoading());
    try {
      const { email } = values;
      const result = await forgotPassword(email);
      if (result.success) {
        toast.success('Success, please check your email.');
        onModalClose();
      } else {
        toast.error('Server Error.');
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message || 'Server Error');
    }
    dispatch(stopLoading());
  };

  return (
    <CommonModal
      modalTitle="Forgot Password?"
      isOpen={isOpen}
      onModalClose={onModalClose}
    >
      <>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          <Form>
            <div className="flex flex-col w-full mt-5">
              <Input
                label="Email"
                name="email"
                type="email"
                placeholder="Email"
              />
            </div>
            <div className="flex items-center justify-between md:mt-8">
              <Button text="Confirm" />
              <Button
                text={'Cancel'}
                type="button"
                onClick={onModalClose}
                className="bg-secondary hover:bg-secondary-500 text-black ml-2"
              />
            </div>
          </Form>
        </Formik>
      </>
    </CommonModal>
  );
};

export default ForgotPasswordModal;
