import Input from 'Components/Input';
import { ProjectSetupFormType } from './index';
import { twMerge } from 'tailwind-merge';

type ProjectBasicSetupProps = {
  values: ProjectSetupFormType;
  className?: string;
  settingsTab?: boolean;
};

const ProjectBasicSetup = (props: ProjectBasicSetupProps) => {
  const { className, settingsTab } = props;
  return (
    <div className={twMerge('flex-col w-full', className)}>
      <div
        className={`flex-col w-full ${settingsTab ? 'border-r mr-2 pr-2' : ''}`}
      >
        <div className="flex w-full justify-center">
          <Input label="Project Name" name="project_name" type="text" />
        </div>
      </div>
    </div>
  );
};

export default ProjectBasicSetup;
