import React, { useState } from 'react';
import Table from 'Components/Table';
import { ColumnDef } from '@tanstack/react-table';
import Button from 'Components/Button';
import AddEditBusinessPartnerModal from './AddEditBusinessPartnerModal';
import { deleteCompany } from 'Services/api';
import ConfirmDeleteModal from 'Components/ConfirmDeleteModal';
import { useDispatch } from 'react-redux';
import { startLoading, stopLoading } from 'Store/loadingSlice';
import { toast } from 'react-toastify';
import { CompanyResponse } from 'Types';
import ActionButtons from 'Components/ActionButtons';

const Dashboard: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<boolean>(true);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<string>('');
  const [selectedBusinessPartnerData, setSelectedBusinessPartnerData] =
    useState<any>(null);
  const dispatch = useDispatch();

  const onDeleteConfirm = async () => {
    dispatch(startLoading());
    try {
      const result = await deleteCompany(deleteId);
      if (result.success) {
        setRefresh(true);
        toast.success('Company deleted successfully!');
      } else {
        toast.error('Something went wrong!');
      }
    } catch (error) {
      console.error(error);
      toast.error('Something went wrong!');
    }
    dispatch(stopLoading());
    setShowDeleteModal(false);
    setDeleteId('');
  };

  const columns = React.useMemo<ColumnDef<CompanyResponse>[]>(
    () => [
      {
        accessorKey: 'company_name',
        header: () => <span>Business Partner</span>,
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        size: 45,
      },
      {
        accessorKey: 'email',
        header: () => <span>Champion</span>,
        cell: (info) => {
          return `${info.row.original.name} ( ${info.row.original.email} )`;
        },
        footer: (props) => props.column.id,
        id: 'lastName',
        size: 45,
      },
      {
        header: () => <div className="w-full text-center">Action</div>,
        accessorKey: 'action',
        cell: ({ row }) => (
          <ActionButtons
            onDeleteClick={() => {
              setShowDeleteModal(true);
              setDeleteId(row.original.company_id);
            }}
            onEditClick={() => {
              setIsModalOpen(true);
              setSelectedBusinessPartnerData(row.original);
            }}
          />
        ),
        footer: (props) => props.column.id,
        size: 10,
      },
    ],
    [],
  );

  return (
    <div className="min-h-screen flex w-full bg-gray-100 p-5">
      <div className="flex flex-col w-full bg-white rounded-lg">
        <div className="flex justify-between w-full items-center mt-3">
          <h1 className="text-base font-medium pl-5">Business Partner List</h1>
          <Button
            text="Add Business Partner"
            className="h-10 w-[200px] mr-5"
            type="button"
            onClick={() => setIsModalOpen(true)}
          />
        </div>
        <Table
          url="/company"
          columns={columns}
          refresh={refresh}
          setRefresh={(value) => setRefresh(value)}
        />
        <AddEditBusinessPartnerModal
          businessPartnerData={selectedBusinessPartnerData}
          isOpen={isModalOpen}
          doRefresh={() => setRefresh(true)}
          onModalClose={() => {
            setSelectedBusinessPartnerData(null);
            setIsModalOpen(false);
          }}
        />
        <ConfirmDeleteModal
          modalTitle="Delete Business Partner"
          modalText="Are you sure you want to delete business partner?"
          isOpen={showDeleteModal}
          onModalClose={() => setShowDeleteModal(false)}
          onConfirm={onDeleteConfirm}
        />
      </div>
    </div>
  );
};

export default Dashboard;
