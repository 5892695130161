import React, { useCallback, useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import {
  SortingState,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { fetchData } from '../Services/api';
import Skeleton from 'react-loading-skeleton';
import NoDataFound from './NoDataFound';

interface TableProps {
  url: string;
  columns: any;
  refresh?: boolean;
  setRefresh?: (value: boolean) => void;
  columnVisibility?: any;
  noDataFoundMessage?: string;
}

const Table: React.FC<TableProps> = (props: TableProps) => {
  const {
    columnVisibility,
    columns,
    url,
    refresh,
    setRefresh,
    noDataFoundMessage,
  } = props;

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [sorting, setSorting] = useState<SortingState>([]);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [tableData, setTableData] = useState<any[]>([]);

  const loadData = useCallback(async () => {
    setLoading(true);
    const data = await fetchData(url);
    setTableData(data.result);
    setTotalPages(Math.ceil(data.result.length / pagination.pageSize));
    setLoading(false);
  }, [pagination, url]);

  useEffect(() => {
    if (refresh && setRefresh) {
      setRefresh(false);
      loadData();
    }
  }, [refresh, setRefresh, loadData]);

  // Handle client-side sorting and pagination
  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      pagination,
      sorting,
      columnVisibility: columnVisibility || {},
    },
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    manualPagination: false, // Allow client-side pagination
    manualSorting: false, // Allow client-side sorting
  });

  const handlePageClick = ({ selected }: any) => {
    setPagination((prev) => ({
      ...prev,
      pageIndex: selected,
    }));
  };

  const getTable = () => {
    if (loading) {
      return <Skeleton count={10} />;
    }
    if (tableData.length === 0) {
      return <NoDataFound customMessage={noDataFoundMessage} />;
    }
    return (
      <table className="w-full" cellPadding={10} cellSpacing={10}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id} className="border-b border-[#D4D9E0]">
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  colSpan={header.colSpan}
                  style={{ width: `${header.getSize()}%` }}
                  className={`text-left text-[#718096] text-xs font-normal`}
                >
                  <div
                    {...{
                      className: header.column.getCanSort()
                        ? 'cursor-pointer select-none'
                        : '',
                      onClick:
                        header.column.columnDef.id === 'action'
                          ? undefined
                          : header.column.getToggleSortingHandler(),
                    }}
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
                    {{
                      asc: ' 🔼',
                      desc: ' 🔽',
                    }[header.column.getIsSorted() as string] ?? null}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id} className="border-b border-[#D4D9E0]">
              {row.getVisibleCells().map((cell) => (
                <td
                  key={cell.id}
                  className={`text-left text-black text-xs font-normal`}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <div className="w-full p-3">
      <div className="w-full bg-white rounded">{getTable()}</div>
      <div className="h-2" />
      <ReactPaginate
        breakLabel="..."
        nextLabel="next >"
        onPageChange={handlePageClick}
        marginPagesDisplayed={3} // Display the first three pages
        pageRangeDisplayed={0} // Hide other page numbers
        pageCount={totalPages}
        previousLabel="< previous"
        renderOnZeroPageCount={null}
        containerClassName={'pagination'}
        activeClassName={'active'}
      />
    </div>
  );
};

export default Table;
