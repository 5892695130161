import Input from 'Components/Input';
import { BusinessPartnerSetupFormType } from './index';
import { bubbleSize, colorsSVG } from 'Assets';
import ColorPicker from 'Components/ColorPicker';
import { twMerge } from 'tailwind-merge';

type BubbleSetupProps = {
  values: BusinessPartnerSetupFormType;
  className?: string;
  settingsTab?: boolean;
};

const BubbleSetup = (props: BubbleSetupProps) => {
  const { className, settingsTab } = props;
  return (
    <div className={twMerge('flex-col w-full', className)}>
      <div
        className={`flex-col w-full ${settingsTab ? 'border-r mr-2 pr-2' : ''}`}
      >
        <div className="flex justify-between text-[16px] font-bold mt-3 mb-3">
          <div className="flex">
            <img src={bubbleSize} alt="Size" /> SIZE
          </div>
          <div className="flex">Project Cost</div>
        </div>

        <div className="flex w-full justify-center">
          <Input
            label="Type"
            name="size_small"
            type="text"
            divClassName="w-auto mr-2"
            className="!bg-white !w-[100px]"
            readOnly
          />
          <Input
            label="Less than (unit of currency)"
            name="project_cost_amount_from"
            type="text"
            placeholder="000"
            // preText="£"
          />
        </div>

        <div className="flex w-full justify-center">
          <Input
            label="Type"
            name="size_medium"
            type="text"
            divClassName="w-auto mr-2"
            className="!bg-white !w-[100px]"
            readOnly
          />
          <Input
            label="Amount From"
            name="project_cost_amount_from"
            type="text"
            placeholder="000"
            // preText="£"
            divClassName="mr-2"
          />
          <Input
            label="Amount To"
            name="project_cost_amount_to"
            type="text"
            placeholder="000"
            // preText="£"
          />
        </div>

        <div className="flex w-full justify-center">
          <Input
            label="Type"
            name="size_large"
            type="text"
            divClassName="w-auto mr-2"
            className="!bg-white !w-[100px]"
            readOnly
          />
          <Input
            label="Greater than"
            name="project_cost_amount_to"
            type="text"
            placeholder="000"
            // preText="£"
          />
        </div>
        {!settingsTab ? <hr /> : null}
      </div>
      <div className="flex-col w-full">
        <div className="flex justify-between text-[16px] font-bold mt-3 mb-3">
          <div className="flex">
            <img src={colorsSVG} alt="Colors" /> COLORS
          </div>
          <div className="flex">Project ROI</div>
        </div>

        <div className="flex w-full justify-center">
          <ColorPicker
            className="min-w-[100px] mr-2"
            label="Type"
            name="low_color"
            colorLabel="Low"
          />
          <Input
            label="Less than"
            name="project_roi_return_from"
            type="text"
            placeholder="00"
            preText="%"
          />
        </div>

        <div className="flex w-full justify-center">
          <ColorPicker
            className="min-w-[100px] mr-2"
            label="Type"
            name="medium_color"
            colorLabel="Medium"
          />
          <Input
            label="Amount From"
            name="project_roi_return_from"
            type="text"
            divClassName="mr-2"
            placeholder="00"
            preText="%"
          />
          <Input
            label="Amount To"
            name="project_roi_return_to"
            type="text"
            placeholder="00"
            preText="%"
          />
        </div>

        <div className="flex w-full justify-center">
          <ColorPicker
            className="min-w-[100px] mr-2"
            label="Type"
            name="high_color"
            colorLabel="High"
          />
          <Input
            label="Greater than"
            name="project_roi_return_to"
            type="text"
            placeholder="00"
            preText="%"
          />
        </div>
      </div>
    </div>
  );
};

export default BubbleSetup;
