import React from 'react';
import Modal from 'react-modal';
import { PiX } from 'react-icons/pi';

// Set the app element for accessibility
Modal.setAppElement('#root');

type CommonModalProps = {
  modalTitle: string;
  isOpen: boolean;
  onModalClose: () => void;
  children: JSX.Element;
};

const CommonModal = (props: CommonModalProps) => {
  const { modalTitle, isOpen, onModalClose, children } = props;
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onModalClose}
      contentLabel="Common Modal"
      className="modal"
      overlayClassName="overlay"
    >
      <div className="flex justify-between items-center">
        <div className="text-2xl font-medium">{modalTitle}</div>
        <button onClick={onModalClose} className="close-button">
          <PiX />
        </button>
      </div>
      {children}
    </Modal>
  );
};

export default CommonModal;
