import { ButtonHTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';

type ButtonProps = {
  text: string;
};

const Button = (
  props: ButtonProps & ButtonHTMLAttributes<HTMLButtonElement>,
) => {
  const { className, text } = props;
  return (
    <button
      {...props}
      className={twMerge(
        'bg-primary flex items-center justify-center hover:bg-primary-500 w-full text-white text-sm font-bold p-3 rounded focus:outline-none focus:shadow-outline',
        className,
      )}
    >
      {text}
    </button>
  );
};

export default Button;
