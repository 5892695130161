import { useEffect, useState } from 'react';
import GraphView from './GraphView';
import ProjectsAndIdeas from './ProjectsAndIdeas';
import Settings from './Settings';
import Tabs from 'Components/Tabs';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'Store/store';
import { Roles } from 'Constants';

type TabsType = 'graph_view' | 'settings' | 'projects_ideas';

const Company = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const tabParam = (searchParams.get('selectedTab') as TabsType) || '';
  const role = useSelector((state: RootState) => state.auth.role);

  const [selectedTab, setSelectedTab] = useState<TabsType>(
    tabParam || 'graph_view',
  );

  useEffect(() => {
    tabParam && selectedTab !== tabParam && setSelectedTab(tabParam);
  }, [tabParam, selectedTab]);

  const getSelectedTabContent = () => {
    if (selectedTab === 'projects_ideas') {
      return <ProjectsAndIdeas />;
    }
    if (selectedTab === 'settings' && role !== Roles.COMPANY_USER) {
      return <Settings />;
    }
    return <GraphView />;
  };

  return (
    <div className="flex w-full flex-col bg-gray-100">
      <div className="flex w-full">
        <Tabs
          selectedTab={selectedTab}
          onClick={(tab) =>
            navigate(('/dashboard?selectedTab=' + tab) as TabsType)
          }
          tabs={[
            {
              label: 'Graph View',
              value: 'graph_view',
              hidden: false,
            },
            {
              label: 'Projects & Ideas',
              value: 'projects_ideas',
              hidden: false,
            },
            {
              label: 'Settings',
              value: 'settings',
              hidden: role === Roles.COMPANY_USER,
            },
          ]}
        />
      </div>
      <div className="flex w-full">{getSelectedTabContent()}</div>
    </div>
  );
};
export default Company;
