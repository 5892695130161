import Input from 'Components/Input';
import SelectInput from 'Components/SelectInput';
import { FieldArray } from 'formik';
import { BusinessPartnerSetupFormType } from './index';
import { twMerge } from 'tailwind-merge';
import { weightOptions } from 'Constants';

type YAxisSetupProps = {
  values: BusinessPartnerSetupFormType;
  className?: string;
};

const YAxisSetup = (props: YAxisSetupProps) => {
  const { values, className } = props;
  return (
    <div className={twMerge('flex-col w-full', className)}>
      <div className="flex w-full">
        <Input
          label="Y-axis name"
          name="yaxis_name"
          type="text"
          placeholder="Yaxis name Example"
        />
      </div>
      <hr />
      <div className="mt-3 flex flex-col w-full">
        <FieldArray name="yaxis_questions">
          {() => (
            <div className="w-full">
              {values.yaxis_questions.length > 0 &&
                values.yaxis_questions.map((question, index) => (
                  <div className="flex w-full" key={'yaxis_questions-' + index}>
                    <Input
                      label={'Question #' + (index + 1)}
                      name={`yaxis_questions.${index}.question`}
                      type="text"
                      placeholder="Business Partner Example"
                      className="min-w-[250px]"
                    />
                    <SelectInput
                      label={'Weight'}
                      name={`yaxis_questions.${index}.weight`}
                      options={weightOptions}
                      outerDivClassName="ml-2 w-[150px]"
                    />
                  </div>
                ))}
            </div>
          )}
        </FieldArray>
      </div>
    </div>
  );
};

export default YAxisSetup;
