import { FieldArray } from 'formik';
import { answerOptions, ProjectSetupFormType } from './index';
import { twMerge } from 'tailwind-merge';
import ChipsInput from 'Components/ChipsInput';

type ProjectXAxisSetupProps = {
  values: ProjectSetupFormType;
  className?: string;
};

const ProjectXAxisSetup = (props: ProjectXAxisSetupProps) => {
  const { values, className } = props;
  return (
    <div className={twMerge('flex-col w-full', className)}>
      <hr />
      <div className="mt-3 flex flex-col w-full">
        <FieldArray name="xaxis_questions">
          {() => (
            <div className="w-full">
              {values.xaxis_questions.length > 0 &&
                values.xaxis_questions.map((question, index) => (
                  <div
                    className="flex flex-col w-full mb-4"
                    key={'xaxis_questions-' + index}
                  >
                    <label
                      className="block text-gray-700 text-xs font-bold mb-2"
                      htmlFor={`xaxis_questions.${index}.weight`}
                    >
                      {question.question}
                    </label>
                    <ChipsInput
                      name={`xaxis_questions.${index}.weight`}
                      options={answerOptions}
                    />
                  </div>
                ))}
            </div>
          )}
        </FieldArray>
      </div>
    </div>
  );
};

export default ProjectXAxisSetup;
