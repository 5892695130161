import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import store from 'Store/store';
import Loader from 'Components/Loader';
import ProtectedRoute from 'Components/ProtectedRoute';
import Login from 'Pages/Login';
import Dashboard from 'Pages/Dashboard';
import BusinessPartner from 'Pages/BusinessPartner';
import BusinessPartnerSetup from 'Pages/BusinessPartnerSetup';
import Users from 'Pages/Users';
import ProjectSetup from 'Pages/ProjectSetup';
import ResetPassword from 'Pages/Users/ResetPassword';
import 'react-toastify/dist/ReactToastify.css';
import 'react-loading-skeleton/dist/skeleton.css';
import './index.css';

const queryClient = new QueryClient();

function App() {
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
        <Loader />
        <Router>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute component={Dashboard} path="/dashboard" />
              }
            />
            <Route
              path="/business-partner"
              element={
                <ProtectedRoute
                  component={BusinessPartner}
                  path="/business-partner"
                />
              }
            />
            <Route
              path="/business-partner-setup"
              element={
                <ProtectedRoute
                  component={BusinessPartnerSetup}
                  path="/business-partner-setup"
                />
              }
            />
            <Route
              path="/project-setup"
              element={
                <ProtectedRoute
                  component={ProjectSetup}
                  path="/project-setup"
                />
              }
            />
            <Route
              path="/users"
              element={<ProtectedRoute component={Users} path="/users" />}
            />
            <Route
              path="/reset-password"
              element={
                <ProtectedRoute
                  component={ResetPassword}
                  path="/reset-password"
                />
              }
            />
            <Route path="/" element={<Navigate to="/dashboard" replace />} />
          </Routes>
        </Router>
      </QueryClientProvider>
    </Provider>
  );
}

export default App;
