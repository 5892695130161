import React from 'react';
import { TailSpin } from 'react-loader-spinner';
import { useSelector } from 'react-redux';
import { RootState } from 'Store/store';

const Loader = () => {
  const isLoading = useSelector((state: RootState) => state.loading.isLoading);
  if (!isLoading) return null;

  return (
    <div className="spinner-overlay">
      <div className="spinner-container">
        <TailSpin height={80} width={80} color="#FF6600" ariaLabel="loading" />
      </div>
    </div>
  );
};

export default Loader;
