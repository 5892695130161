import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { startLoading, stopLoading } from 'Store/loadingSlice';
import Input from 'Components/Input';
import Button from 'Components/Button';
import { useNavigate } from 'react-router-dom';
import { resetPassword } from 'Services/api';

type FormType = {
  current_password: string;
  new_password: string;
  confirm_password: string;
};

const ResetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const initialValues: FormType = {
    current_password: '',
    new_password: '',
    confirm_password: '',
  };

  const validationSchema = Yup.object({
    current_password: Yup.string().required('Required'),
    new_password: Yup.string()
      .required('Required')
      .min(8, 'Password must be at least 8 characters'),
    confirm_password: Yup.string()
      .required('Required')
      .oneOf([Yup.ref('new_password'), ''], 'Passwords must match'),
  });

  const onSubmit = async (values: FormType) => {
    dispatch(startLoading());
    try {
      const result = await resetPassword(values);
      if (result.success) {
        toast.success('Password updated!');
      } else {
        toast.error(
          result?.data === 404 ? 'Incorrect Password' : 'Something went wrong!',
        );
      }
    } catch (error: any) {
      console.error(error);
      toast.error(
        error?.response?.status === 404
          ? 'Incorrect Password'
          : 'Something went wrong!',
      );
    }
    dispatch(stopLoading());
  };

  return (
    <div className="flex items-center justify-center mt-5 lg:mt-0 lg:min-h-screen bg-gray-100">
      <div className="bg-white w-[90%] lg:w-[50%] p-10 rounded-lg shadow-lg">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          <Form className="w-full mt-4">
            <div className="flex flex-col w-full">
              <Input
                label="Current Password"
                name="current_password"
                type="password"
                placeholder="**********"
              />
            </div>
            <div className="flex flex-col w-full">
              <Input
                label="New Password"
                name="new_password"
                type="password"
                placeholder="**********"
              />
            </div>
            <div className="flex flex-col w-full">
              <Input
                label="Confirm Password"
                name="confirm_password"
                type="password"
                placeholder="**********"
              />
            </div>

            <div className="flex items-center justify-between md:mt-8">
              <Button
                text={'Cancel'}
                type="button"
                onClick={() => {
                  navigate(-1);
                }}
                className="bg-secondary hover:bg-secondary-500 text-black mr-2"
              />
              <Button text="Update" />
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
};

export default ResetPassword;
