import Button from 'Components/Button';
import { Formik, Form, FormikHelpers } from 'formik';
import { useCallback, useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';
import ProjectXAxisSetup from './ProjectXAxisSetup';
import ProjectYAxisSetup from './ProjectYAxisSetup';
import ProjectBubbleSetup from './ProjectBubbleSetup';
import { CompanySettingsResponse, ProjectRequestType } from 'Types';
import {
  addProject,
  getCompanySettings,
  getProject,
  updateProject,
} from 'Services/api';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { startLoading, stopLoading } from 'Store/loadingSlice';
import { toast } from 'react-toastify';
import ProjectBasicSetup from './ProjectBasicSetup';

export const answerOptions = [
  {
    label: '1',
    value: 1,
  },
  {
    label: '2',
    value: 2,
  },
  {
    label: '3',
    value: 3,
  },
  {
    label: '4',
    value: 4,
  },
  {
    label: '5',
    value: 5,
  },
  {
    label: '6',
    value: 6,
  },
  {
    label: '7',
    value: 7,
  },
  {
    label: '8',
    value: 8,
  },
  {
    label: '9',
    value: 9,
  },
  {
    label: '10',
    value: 10,
  },
];

export type ProjectSetupFormType = {
  project_name: string;
  project_cost: number;
  project_roi: number;
  xaxis_name: string;
  yaxis_name: string;
  xaxis_questions: Array<{ id: number; question: string; weight: number }>;
  yaxis_questions: Array<{ id: number; question: string; weight: number }>;
};

const ProjectSetup = () => {
  const [step, setStep] = useState<number>(1);
  const [companySettingsResponse, setCompanySettingsResponse] =
    useState<CompanySettingsResponse | null>(null);
  const [projectData, setProjectData] = useState<ProjectRequestType | null>(
    null,
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');

  const getSettingsData = useCallback(async () => {
    dispatch(startLoading());
    const data = await getCompanySettings();
    setCompanySettingsResponse(data);
    if (id) {
      const result = await getProject(id);
      if (result.data) {
        setProjectData(result.data);
      }
    }
    dispatch(stopLoading());
  }, [dispatch, id]);

  useEffect(() => {
    getSettingsData();
  }, [getSettingsData]);

  const initialValues: ProjectSetupFormType = useMemo(() => {
    const xAxisQuestions =
      companySettingsResponse?.company_settings?.x_axis_questions?.map((q) => ({
        id: q.id,
        question: q.question,
        weight:
          projectData?.x_axis_answers?.find((answer) => answer.id === q.id)
            ?.value || 1,
      })) || [];
    const yAxisQuestions =
      companySettingsResponse?.company_settings?.y_axis_questions?.map((q) => ({
        id: q.id,
        question: q.question,
        weight:
          projectData?.y_axis_answers?.find((answer) => answer.id === q.id)
            ?.value || 1,
      })) || [];
    console.log(projectData?.y_axis_answers, yAxisQuestions);

    return {
      project_name: projectData?.project_name || '',
      project_cost: projectData?.cost || 0,
      project_roi: projectData?.roi || 0,
      xaxis_name: companySettingsResponse?.company_settings?.x_axis_label || '',
      yaxis_name: companySettingsResponse?.company_settings?.y_axis_label || '',
      xaxis_questions: xAxisQuestions,
      yaxis_questions: yAxisQuestions,
    };
  }, [companySettingsResponse, projectData]);

  const stepOneValidationSchema = Yup.object({
    project_name: Yup.string().required('Required'),
  });

  const stepTwoValidationSchema = Yup.object({
    xaxis_questions: Yup.array().of(
      Yup.object({
        question: Yup.string().required('Question is required'),
        weight: Yup.number().required('Weight is required'),
      }),
    ),
  });

  const stepThreeValidationSchema = Yup.object({
    yaxis_questions: Yup.array().of(
      Yup.object({
        question: Yup.string().required('Question is required'),
        weight: Yup.number().required('Weight is required'),
      }),
    ),
  });

  const stepFourValidationSchema = Yup.object({
    project_cost: Yup.number().required('Required'),
    project_roi: Yup.number().required('Required'),
  });

  const getValidationSchema = () => {
    switch (step) {
      case 1:
        return stepOneValidationSchema;
      case 2:
        return stepTwoValidationSchema;
      case 3:
        return stepThreeValidationSchema;
      case 4:
        return stepFourValidationSchema;
      default:
        return stepOneValidationSchema;
    }
  };

  const onSubmit = async (values: ProjectSetupFormType) => {
    dispatch(startLoading());
    try {
      const requestData = {
        project_name: values.project_name,
        x_axis_answers: values.xaxis_questions.map((ans) => ({
          id: ans.id,
          value: ans.weight,
        })),
        y_axis_answers: values.yaxis_questions.map((ans) => ({
          id: ans.id,
          value: ans.weight,
        })),
        cost: values.project_cost,
        roi: values.project_roi,
      };
      let result;
      if (id) {
        result = await updateProject(requestData, id);
      } else {
        result = await addProject(requestData);
      }
      if (result.success) {
        toast.success(id ? 'Project updated!' : 'Project added successfully!');
        navigate('/dashboard?selectedTab=projects_ideas');
      } else {
        toast.error('Something went wrong!');
      }
    } catch (error) {
      console.error(error);
      toast.error('Something went wrong!');
    }
    dispatch(stopLoading());
  };

  const handleSubmit = (
    values: ProjectSetupFormType,
    actions: FormikHelpers<ProjectSetupFormType>,
  ) => {
    if (step === 4) {
      // Final step: submit form
      onSubmit(values);
    } else {
      // Go to next step
      setStep(step + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  const getTitleText = () => {
    switch (step) {
      case 1:
        return 'Project';
      case 2:
        return initialValues.xaxis_name;
      case 3:
        return initialValues.yaxis_name;
      case 4:
        return 'Bubble';
      default:
        return initialValues.xaxis_name;
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white p-10 rounded-lg shadow-lg">
        <div className="flex justify-between items-center">
          <div className="text-2xl font-bold mr-2">{getTitleText()}</div>
          <div className="text-base text-primary">Step {step}/4</div>
        </div>
        {step === 2 || step === 3 ? (
          <div className="text-gray-500 text-xs mb-4">Low: 1 and High: 10</div>
        ) : null}
        <Formik
          initialValues={initialValues}
          validationSchema={getValidationSchema()}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ values }) => (
            <Form className="w-full mt-4">
              {step === 1 ? <ProjectBasicSetup values={values} /> : null}
              {step === 2 ? <ProjectXAxisSetup values={values} /> : null}
              {step === 3 ? <ProjectYAxisSetup values={values} /> : null}
              {step === 4 ? <ProjectBubbleSetup values={values} /> : null}
              <div className="flex items-center justify-between md:mt-8">
                <Button
                  text={step > 1 ? 'Back' : 'Cancel'}
                  type="button"
                  onClick={() => {
                    if (step > 1) {
                      setStep((step) => step - 1);
                    } else {
                      navigate('/dashboard?selectedTab=projects_ideas');
                    }
                  }}
                  className="bg-secondary hover:bg-secondary-500 text-black min-w-[150px]"
                />
                <Button
                  text={step === 4 ? 'Finish' : 'Continue'}
                  type="submit"
                  className="ml-2 min-w-[250px]"
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};
export default ProjectSetup;
