import React from 'react';
import { useField, useFormikContext } from 'formik';
import { twMerge } from 'tailwind-merge';

interface ChipsInputProps {
  name: string;
  options: Array<{
    label: string;
    value: number;
  }>;
  className?: string;
}

const ChipsInput: React.FC<ChipsInputProps> = ({
  name,
  options,
  className,
}) => {
  const [field] = useField(name);
  const { setFieldValue } = useFormikContext();

  const handleChipClick = (option: string | number) => {
    setFieldValue(name, option);
  };

  return (
    <div className={twMerge('flex space-x-2', className)}>
      {options.map((option, index) => (
        <button
          key={index}
          type="button"
          onClick={() => handleChipClick(option.value)}
          className={twMerge(
            'px-2 py-1 rounded border transition-colors',
            field.value === option.value
              ? 'bg-primary text-white'
              : 'bg-gray-200 text-gray-700 hover:bg-gray-300',
          )}
        >
          {option.label}
        </button>
      ))}
    </div>
  );
};

export default ChipsInput;
