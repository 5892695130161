export enum Roles {
  SUPER_ADMIN = 'SUPER_ADMIN',
  COMPANY_ADMIN = 'COMPANY_ADMIN',
  COMPANY_USER = 'COMPANY_USER',
}

export const permissions = {
  [Roles.SUPER_ADMIN]: [
    '/project-setup',
    '/business-partner',
    '/business-partner-setup',
    '/users',
    '/dashboard',
    '/reset-password',
  ],
  [Roles.COMPANY_ADMIN]: [
    '/users',
    '/dashboard',
    '/project-setup',
    '/reset-password',
  ],
  [Roles.COMPANY_USER]: ['/dashboard', '/project-setup', '/reset-password'],
};

export const sideBarRoutes = [
  {
    label: 'Dashboard',
    route: '/dashboard',
    isPermitted: false,
  },
  {
    label: 'Business Partner',
    route: '/business-partner',
    isPermitted: false,
  },
  {
    label: 'Users',
    route: '/users',
    isPermitted: false,
  },
];

export const weightOptions = [
  {
    label: '1',
    value: 1,
  },
  {
    label: '2',
    value: 2,
  },
  {
    label: '4',
    value: 4,
  },
];

export const getPermittedRoutes = (currentUserRole: Roles) =>
  sideBarRoutes.map((route) => ({
    ...route,
    isPermitted: permissions[currentUserRole]?.includes(route.route),
  }));
