import React, { useState } from 'react';
import Table from 'Components/Table';
import { ColumnDef } from '@tanstack/react-table';
import ActionButtons from 'Components/ActionButtons';
import Button from 'Components/Button';
import { useNavigate } from 'react-router-dom';
import ConfirmDeleteModal from 'Components/ConfirmDeleteModal';
import { useDispatch, useSelector } from 'react-redux';
import { startLoading, stopLoading } from 'Store/loadingSlice';
import { toast } from 'react-toastify';
import { deleteProject } from 'Services/api';
import { RootState } from 'Store/store';
import { Roles } from 'Constants';

type Project = {
  company_id: string;
  cost: number;
  project_id: string;
  roi: number;
  x_axis_answers: any;
  y_axis_answers: any;
  created_by: string;
  is_editable: boolean;
};

const ProjectAndIdeas: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<boolean>(true);
  const [deleteId, setDeleteId] = useState<string>('');
  const role = useSelector((state: RootState) => state.auth.role);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onDeleteConfirm = async () => {
    dispatch(startLoading());
    try {
      const result = await deleteProject(deleteId);
      if (result.success) {
        setRefresh(true);
        toast.success('Project deleted successfully!');
      } else {
        toast.error('Something went wrong!');
      }
    } catch (error) {
      console.error(error);
      toast.error('Something went wrong!');
    }
    dispatch(stopLoading());
    setIsModalOpen(false);
    setDeleteId('');
  };

  const columns = React.useMemo<ColumnDef<Project>[]>(
    () => [
      {
        accessorKey: 'Rank',
        header: () => <span>Id</span>,
        cell: (info) => parseInt(info.row.id) + 1,
        footer: (props) => props.column.id,
        size: 10,
      },
      {
        accessorKey: 'project_name',
        header: () => <span>Project Name</span>,
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        size: 20,
      },
      {
        accessorKey: 'created_by',
        header: () => <span>Project Owner</span>,
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        size: 20,
      },
      {
        accessorKey: 'cost',
        header: () => <span>Project Cost</span>,
        cell: (info) => {
          // Get the raw cost value
          const cost = info.getValue() as number;

          // Format the cost value using Intl.NumberFormat
          const formattedCost = new Intl.NumberFormat('en-GB', {
            style: 'decimal',
          }).format(cost);

          // Return the formatted value with the '£' symbol
          return `${formattedCost}`;
        },
        footer: (props) => props.column.id,
        size: 15,
      },
      {
        accessorKey: 'roi',
        header: () => <span>Project ROI</span>,
        cell: (info) => {
          const roi = info.getValue();
          return `${roi} %`;
        },
        footer: (props) => props.column.id,
        size: 15,
      },
      {
        accessorKey: 'score',
        header: () => <span>Score</span>,
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        size: 15,
      },
      {
        header: () => <div className="w-full text-center">Action</div>,
        accessorKey: 'action',
        cell: ({ row }) => (
          <ActionButtons
            onDeleteClick={() => {
              setIsModalOpen(true);
              setDeleteId(row.original.project_id);
            }}
            onEditClick={() => {
              navigate(`/project-setup?id=${row.original.project_id}`);
            }}
            hideAction={!row.original.is_editable}
          />
        ),
        footer: (props) => props.column.id,
        size: 10,
        id: 'action',
      },
    ],
    [navigate],
  );

  return (
    <div className="flex w-full bg-gray-100 p-5">
      <div className="flex flex-col w-full bg-white rounded-lg">
        <div className="flex justify-between w-full items-center mt-3">
          <h1
            className={`text-base font-medium pl-5 ${role !== Roles.COMPANY_USER ? '' : 'py-2'}`}
          >
            Project & Ideas
          </h1>
          <Button
            type="button"
            text="Add Project"
            onClick={() => navigate('/project-setup')}
            className="max-w-[200px] mr-3 mt-0"
          />
        </div>
        <Table
          url="/project"
          columns={columns}
          refresh={refresh}
          setRefresh={setRefresh}
          noDataFoundMessage="Please input your first project or idea"
        />
        <ConfirmDeleteModal
          modalTitle="Delete Project"
          modalText="Are you sure you want to delete the project?"
          isOpen={isModalOpen}
          onModalClose={() => setIsModalOpen(false)}
          onConfirm={onDeleteConfirm}
        />
      </div>
    </div>
  );
};

export default ProjectAndIdeas;
