import React from 'react';
import { useField } from 'formik';
import { twMerge } from 'tailwind-merge';

interface InputProps {
  label: string;
  name: string;
  type?: string;
  placeholder?: string;
  className?: string;
  divClassName?: string;
  readOnly?: boolean;
  preText?: string;
}

const Input: React.FC<InputProps> = ({
  divClassName,
  className,
  label,
  preText,
  ...props
}) => {
  const [field, meta] = useField(props);

  return (
    <div className={twMerge('mb-4 w-full', divClassName)}>
      {label ? (
        <label
          className="block text-gray-700 text-xs font-bold mb-2"
          htmlFor={props.name}
        >
          {label}
        </label>
      ) : null}
      <div className="relative flex items-center">
        {preText && (
          <span className="absolute left-3 text-gray-700">{preText}</span>
        )}
        <input
          className={twMerge(
            `input-field border rounded w-full p-2 ${preText ? 'pl-8' : 'pl-3'} text-gray-700 leading-tight ${
              meta.touched && meta.error
                ? 'border-red-500 input-invalid-border'
                : ''
            }`,
            className,
          )}
          {...field}
          {...props}
        />
      </div>
      {meta.touched && meta.error ? (
        <p className="text-invalid text-xs">{meta.error}</p>
      ) : null}
    </div>
  );
};

export default Input;
